<template>
	<div id="landChange">
		<page-land-change :is-shop="0"></page-land-change>
	</div>
</template>
<script>
	import PageLandChange from '@/components/layout/land/page-land-change.vue'
	export default{
		components:{
			PageLandChange
		},
	}
</script>

<style>
</style>