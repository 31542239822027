<template>
	<!-- 交换订单 -->
	<div class="land-change">
		<div class="el-content mb12">
			<a-input-search
				v-model:value="search.key"
				placeholder="输入用户昵称 电话"
				enter-button
				style="width: 400px;"
				@search="getChangeOrder(1,info.limit)"
			/>
		</div>
		<div class="el-content">
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'发布人',dataIndex:'account.nickname'},
				{title:'已有作物',dataIndex:'have',slots:{customRender:'have'}},
				{title:'交换人',dataIndex:'change_user_name'},
				{title:'交换作物',dataIndex:'change',slots:{customRender:'change'}},
				{title:'发布时间',dataIndex:'create_time'},
				{title:'交换期限(天)',dataIndex:'day'},
				{title:'状态',dataIndex:'status',slots:{customRender:'status'}},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
			    <template #have="{record}">
					<div class="seed-item" v-for="(item,index) in record.details" :key="index">
						<img :src="item.seed.cover" alt="">
						<span>{{ item.seed.send_name}} {{ item.weight}}kg</span>
					</div>
				</template>
			    <template #change="{record}">
					<div class="seed-item" v-if="record.changgeseed">
						<img :src="record.changgeseed.cover" alt="">
						<span>{{ record.changgeseed.send_name}} {{ record.exchange_plant_weight}}kg</span>
					</div>
				</template>
			    <template #status="{record}">
					<a-tag v-if="getStatus(record,true) != 5" :color="getStatus(record,true)">{{getStatus(record,false)}}</a-tag>
				</template>
			    <template #action="{record}">
					<a-space>
						<!-- <router-link :to="{path:'/land/change_detail',query:{id:record.id}}">
							<kd-button icon="ri-more-fill" 
								type="primary" 
								title="详情" 
								v-has="{action:'/land/change_detail',plat:isShop}">
							</kd-button>
						</router-link> -->
						<router-link :to="{path:'/land/delivery_detail',query:{id:record.id}}">
							<kd-button icon="ri-takeaway-line" 
								type="purple" 
								title="查看配送信息" 
								v-has="{action:'wxapp_land_change_delivery',plat:isShop}">
							</kd-button>
						</router-link>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination 
					show-size-changer 
					:default-current="info.page" 
					:total="info.count" 
					@showSizeChange="(page,e)=>{ getChangeOrder(info.page, e); }"
					@change="e=>{getChangeOrder(e, info.limit);}"/>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import landModel from '@/api/land.js'
export default{
	name:"page-land-change",
	props:{
		isShop:{
			type:Number,
			default:0
		}
	},
	setup(){
		const _d = reactive({
			search:{key:''},
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
		})
		getChangeOrder(1,_d.info.limit)
		function getChangeOrder(page,limit){
			landModel.getLandExchangeOrder(page,limit,_d.search,res=>{
				_d.info = { limit,...res}
				console.log('_d.',_d.info);
			})
		}
		const getStatus = ( d,c )=>{
			if( d.is_cancel == 1 ) return c ? "#999" : "已取消"
			if( d.pay_status == 0 ) return c ? "#FF0066" : "待支付"
			if( d.order_status == 1 ) return c ? "#0099FF" : "交换中"
			if( d.order_status == 2 || d.order_status == 3) return c ? "#00CC66": "交换成功"
			return c ? 5 : ""
		}
		return{
			...toRefs(_d),
			getChangeOrder,
			getStatus
		}
	}
}
</script>
<style lang="scss">
.land-change{
	.seed-item{
		display: flex;
		align-items: center;
		img{
			width: 24px;
			height: 24px;
			margin-right: 6px;
			margin-bottom: 8px;
		}
	}
}
</style>
